import axios from "axios";
import router from "../../router/index";
import i18n from "../../i18n";
import urls from "../../locales/urls.json";

const state = {
  DOData: { data: { series: [], movies: [] } },
  currentVersion: "",
  isDark: "",
  locale: "",
};
const getters = {
  // state verisini çağırmak için kullanılır
  getDOData(state) {
    return state.DOData;
  },
  getIsDark(state) {
    return state.isDark;
  },
  getLocale(state) {
    return state.locale;
  },
  getLocaleLink: (state) => (payload) => {
    let path = payload.replace("/en", "");
    if (i18n.global.locale.value == "tr") {
      for (var key in urls) {
        path = path.replace(urls[key], key);
      }
      return path;
    } else {
      for (var key in urls) {
        path = path.replace(key, urls[key]);
      }
      return "/" + i18n.global.locale.value + path;
    }
  },
  getLocaleDifficulty: (state) => (payload) => {
    if (i18n.global.locale.value == "tr") {
      return payload;
    } else {
      if (payload == "Çok Kolay") {
        return "Beginner";
      } else if (payload == "Kolay") {
        return "High Beginner";
      } else if (payload == "Orta") {
        return "Intermediate";
      } else if (payload == "Zor") {
        return "Advanced";
      }
    }
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  setDOData(state, payload) {
    state.DOData = payload;
  },
  setVersion(state, payload) {
    state.currentVersion = payload;
  },
  clearDOData(state) {
    state.DOData = "";
    localStorage.removeItem("DOData");
  },
  setIsDark(state, payload) {
    state.isDark = payload;
    localStorage.setItem("isDark", payload.toString());
  },
  setLocale(state, payload) {
    state.locale = payload;
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  downloadData({ commit, dispatch, state }) {
    axios.get("/api/Home/GetContent").then((response) => {
      let contentData = response.data;
      contentData["series"] = contentData["series"].reverse();
      contentData["movies"] = contentData["movies"].reverse();
      axios.get("/api/Home/GetContentVersion").then((response) => {
        let versionData = response.data;
        commit("setDOData", {
          data: contentData,
          version: versionData,
        });
        localStorage.setItem("DOData", JSON.stringify(state.DOData));
      });
    });
  },
  initDOData({ commit, state, dispatch }) {
    let DOData = JSON.parse(localStorage.getItem("DOData"));

    if (DOData) {
      axios
        .get("/api/Home/GetContentVersion")
        .then((response) => {
          commit("setVersion", response.data);
        })
        .finally(() => {
          if (DOData.version == state.currentVersion) {
            commit("setDOData", DOData);
          } else {
            commit("clearDOData");
            dispatch("downloadData");
          }
        });
    } else {
      dispatch("downloadData");
    }
  },
  initLocale({ commit, state, dispatch, getters }) {
    let locale_data = localStorage.getItem("DOLocale");
    if (locale_data) {
      i18n.global.locale.value = locale_data;
      axios.defaults.headers.common["lang"] = locale_data;
      router.push({ path: getters.getLocaleLink(window.location.pathname) });
    } else {
      if (window.location.pathname.includes("/en")) {
        i18n.global.locale.value = "en";
        axios.defaults.headers.common["lang"] = "en";
        localStorage.setItem("DOLocale", "en");
      } else {
        i18n.global.locale.value = "tr";
        axios.defaults.headers.common["lang"] = "tr";
        localStorage.setItem("DOLocale", "tr");
      }
    }
  },
  initDark({ commit, state, dispatch }) {
    if (localStorage.getItem("isDark") == "true") {
      state.isDark = true;
    } else {
      state.isDark = false;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
